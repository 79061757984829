.root {
	color: white;
	background-color: #061527;

	main {
		scrollbar-width: thin;
		scrollbar-color: #34576a #061527;
	}

	main::-webkit-scrollbar-thumb {
		background: #34576a;
		border-radius: 20px;
		border: 4px solid #061527;
	}

	main::-webkit-scrollbar {
		background: transparent;
	}
}