@import '@styles/mixins';
@import '@styles/variables';

.root {
	position: relative;
	display: flex;
	flex-direction: column;

	&.column-reverse {
		flex-direction: column-reverse;
	}

	&.no-wrap {
		flex-wrap: nowrap;
	}

	&.wrap {
		flex-wrap: wrap;
	}

	&.wrap-reverse {
		flex-wrap: wrap-reverse;
	}

	&.force-flex-direction {
		&.row {
			flex-direction: row;
		}

		&.row-reverse {
			flex-direction: row-reverse;
		}
	}

	&.disabled {
		opacity: 0.5;
	}
}

.toggle {
	position: absolute;
    right: 5px;
	top: 5px;
	display: flex;
	align-items: center;
	justify-content: center;
	color: #000000;
	font-size: 12px;
	line-height: 1;
	z-index: 2;

	.icon {
		font-size: 18px;
		margin-left: 5px;
		cursor: pointer;
	}

	&:focus {
		outline: none;
	}
}

@media screen and (min-width: 640px) {
	.root {
		&.row {
			flex-direction: row;
		}

		&.row-reverse {
			flex-direction: row-reverse;
		}

		&.column {
			flex-direction: column;
		}

		&.column-reverse {
			flex-direction: column-reverse;
		}
	}
}
