.carousel {
	position: relative;
}

.navigation-button {
	display: flex;
	position: absolute;
	align-items: center;
	justify-content: center;
	background: transparent;
	border: none;
	width: 70px;
	height: 100px;
	top: calc(50% - 50px);
	z-index: 1;

	&.left {
		left: 0;
	}

	&.right {
		right: 0;
	}

	.inner {
		display: flex;
		align-items: center;
		justify-content: center;
		font-size: 32px;
		background-color: rgba(0, 0, 0, 0.5);
		width: 36px;
		height: 40px;
		color: white;
		border-radius: 5px;
	}
}

.dot-group {
	display: flex;
	align-items: center;
	justify-content: center;
	margin-top: 16px;

	button {
		background-color: rgba(255, 255, 255, 0.3);
		border-radius: 50%;
		width: 16px;
		height: 16px;
		border: 1px solid rgba(0, 0, 0, 0.1);
		margin: 0 2px;
	}

	:global {
		.carousel__dot--selected {
			background-color: rgba(0, 0, 0, 0.05) !important;
		}
	}

	&.modal {
		button {
			background-color: rgba(0, 0, 0, 0.05) !important;
			border: 1px solid rgba(255, 255, 255, 0.2);
		}

		:global {
			.carousel__dot--selected {
				background-color: rgba(255, 255, 255, 0.2) !important;
			}
		}
	}
}
