.root {
	&.dragging {
		background-color: #EBF6EF;
		z-index: 999;
	}

	&:hover {
		background-color: #dbefff;
	}
}
