.root {
	display: flex;
	flex-direction: column;
	width: 100%;

	&.sticky {
		position: sticky;
		top: 0;
		z-index: 9;
	}
}
