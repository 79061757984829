@import '@styles/mixins';
@import '@styles/variables';

.root {
	position: relative;
	cursor: pointer;
	border: 2px solid white;

	&:hover {
		border-color: $blue;

		> .label {
			display: flex;
		}
	}

	&.selected {
		> .label {
			display: flex;
			background-color: $green;
		}

		&:after {
			content: '';
			position: absolute;
			z-index: 1;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			pointer-events: none;
			background-color: rgba(67, 172, 106, 0.3);
		}
	}

	.label {
		display: none;
		align-items: center;
		justify-content: center;
		position: absolute;
		top: -19px;
		left: 0px;
		height: 19px;
		padding: 5px 8px;
		font-size: 10px;
		color: white;
		background-color: $blue;
		pointer-events: none;
		white-space: pre;
	}

	.input {
		display: flex;
		width: 100%;
		border: 1px solid $line-color;
		background: transparent;
		box-shadow: none;
		padding: 8px;
		height: 37px;
		font-size: 14px;
		font-family: "Helvetica Neue", "Helvetica", "Arial", sans-serif;
		color: rgba(0, 0, 0, 0.75);
		@include round-corner(5px);
	}
}
