@import '@styles/mixins';
@import '@styles/variables';

.root {
	white-space: pre-line;

	&.editable {
		position: relative;
		cursor: pointer;
		border: 1px dashed $line-color;

		&:hover {
			border-color: $blue;

			.edit-actions {
				display: flex;
			}
		}
	}

	.icon {
		background-color: $blue;
	}
}

.edit-actions {
	display: none;
	position: absolute;
	bottom: -21px;
	right: 0;
}

.icon {
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 0 3px;
	font-size: 12px;
	width: 20px;
	height: 20px;
	color: white;
	@include round-bottom-corners(5px);
}

