.advChart {
	@media print {
		.recharts-wrapper,
		.recharts-surface,
		.recharts-legend-wrapper {
			width: 100% !important;
			height: 100% !important;
		}
	}
}
