@import '@styles/variables';

.root {
	position: relative;
	background-color: #efefef;
	background-position: center center;
	background-repeat: no-repeat;
	background-size: cover;
	cursor: pointer;
	border: 2px solid white;

	&:hover {
		border-color: $blue;

		> .label {
			display: flex;
		}
	}

	&.active:after {
		content: '';
		position: absolute;
		z-index: 1;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		background-color: rgba(2, 183, 226, 0.3);	
	}

	&.selected {
		> .label {
			display: flex;
			background-color: $green;
		}

		&:after {
			content: '';
			position: absolute;
			z-index: 1;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			pointer-events: none;
			background-color: rgba(67, 172, 106, 0.3);
		}
	}

	.label {
		display: none;
		align-items: center;
		justify-content: center;
		position: absolute;
		top: 0px;
		left: 0px;
		height: 19px;
		padding: 5px 8px;
		font-size: 10px;
		color: white;
		background-color: $blue;
		pointer-events: none;
		white-space: pre;
	}
}
