@import '@styles/variables';
@import '@styles/mixins';

.root {
	display: flex;
	align-items: center;
	justify-content: center;
	background-position: center center;
	background-repeat: no-repeat;
	background-size: cover;
  cursor: pointer;

	.change-text,
	.change-icon {
		display: none;
	}
}
