@import '@styles/variables';

.root {
	display: flex;
	flex-direction: column;
	width: 100%;
	background-size: cover;
	background-position: center center;
	background-repeat: no-repeat;

	&:hover .add-section {
		display: flex;
		width: 100%;
	}

	&:hover .select-section {
		display: flex;
	}

	&.customizing-template {
		position: relative;
	}

	&.explode-sections {
		position: relative;
		border: 1px solid $line-color;
		background-color: white;
		margin-bottom: 16px;
		box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.05);
	}
}

.add-section {
	display: none;
	position: absolute;
	bottom: 0;
	left: 0;
	right: 0;
	align-items: center;
	justify-content: center;
	cursor: pointer;
	color: white;
	height: 3px;
	background-color: $blue;
	z-index: 1;

	.icon-holder {
		display: flex;
		align-items: center;
		justify-content: center;
		border-radius: 15px;
		height: 30px;
		width: 30px;
		background-color: $blue;
	}
}

.select-section {
	display: none;
	align-items: center;
	justify-content: center;
	cursor: pointer;
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: rgba(255, 255, 255, 0.7);
	z-index: 9;
}
