.root {
	position: relative;
}

.root .button{
	visibility: hidden;
}

.root:hover .button{
    visibility: visible;
}

.button {
	cursor: pointer;
	align-items: center;
	border-radius: 5px;
	display: flex;
	height: 35px;
	justify-content: center;
	position: absolute;
	top: 8px;
	right: 8px;
	width: 35px;
}
