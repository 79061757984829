@import '@styles/mixins';

.root {
	position: relative;

	&.sidebar {
		display: contents;
	}

	&.disabled {
		opacity: 0.5;
	}
}

.toggle {
	position: absolute;
    right: 30px;
	top: 30px;
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 12px;
	padding: 4px;
	line-height: 1;
	z-index: 9;
	background-color: white;
	@include round-corner(5px);

	.icon {
		font-size: 18px;
		margin-left: 5px;
		cursor: pointer;
	}

	&:focus {
		outline: none;
	}
}