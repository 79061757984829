.navigation-button {
	display: flex;
	position: absolute;
	align-items: center;
	justify-content: center;
	background: transparent;
	border: none;
	width: 70px;
	height: 100px;
	z-index: 13;

	&.left {
		left: -10px;
	}

	&.right {
		right: -10px;
	}

	.inner {
		display: flex;
		align-items: center;
		justify-content: center;
		font-size: 32px;
		width: 36px;
		height: 40px;
		color: rgba(0, 0, 0, 0.5);
		border-radius: 5px;
	}
}

