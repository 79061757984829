.root {
	background-color: none;

	&.dragging-over {
		background-color: rgba(99, 225, 255, 0.15);
		animation-name: color;
		animation-duration: 2s;
		animation-timing-function: ease-in-out;
		animation-iteration-count: infinite;
	}
}

@keyframes color {
	0% {
		background-color: rgba(99, 225, 255, 0.15)
	}

	50% {
		background-color: rgba(99, 225, 255, 0.35)
	}

	100% {
		background-color: rgba(99, 225, 255, 0.15);
	}
}
