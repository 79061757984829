@import '@styles/mixins';
@import '@styles/variables';

.root {
	position: relative;

	.input {
		display: flex;
		width: 100%;
		border: 1px solid $line-color;
		background: transparent;
		box-shadow: none;
		padding: 8px;
		height: 37px;
		font-size: 14px;
		font-family: "Helvetica Neue", "Helvetica", "Arial", sans-serif;
		color: rgba(0, 0, 0, 0.75);
		@include round-corner(5px);
	}

	.disabled {
		display: flex;
		width: 100%;
		padding: 8px;
	}
}
