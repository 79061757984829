@import '@styles/variables';

.root {
	position: relative;
	display: flex;
	flex-direction: column;
	width: 100%;
	cursor: pointer;
	border: 2px solid white;
	border-bottom: 2px dashed $line-color;

	&:hover {
		border: 2px solid $line-color;

		> .label {
			display: flex;
			width: fit-content;
		}
	}

	&.active:after {
		content: '';
		position: absolute;
		z-index: 1;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		background-color: rgba(2, 183, 226, 0.3);	
	}

	.label {
		display: none;
		align-items: center;
		justify-content: center;
		position: absolute;
		top: -21px;
		left: -3px;
		height: 19px;
		padding: 5px 8px;
		font-size: 10px;
		color: white;
		background-color: $line-color;
		pointer-events: none;
	}
}

.drop-zone {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
	padding: 15px;
	font-size: 14px;
	color: $line-color;
	border-top: 2px dashed $line-color;
}

.add-group {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
	padding: 10px;
	color: $line-color;
	font-size: 14px;
	text-decoration: none;
	outline: none;
	cursor: pointer;

	&:hover {
		color: $blue;
	}
}
