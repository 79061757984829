@import '@styles/variables';

.root {
	cursor: pointer;
	border: 1px dashed $line-color;

	.edit-overlay {
		display: none;
		background-color: rgba(255, 255, 255, 0.7);
		z-index: 998;
	}

	&:hover {
		border-color: $blue;

		.edit-overlay {
			display: flex;
		}
	}
}
