.root {
	position: relative;
	display: flex;
	flex-direction: column;

	a {
		color: rgb(0,0,0);
		text-decoration: none;
	}
}
