@import '@styles/mixins';
@import '@styles/variables';

.image {
	align-items: center;
	background-color: $line-color;
	background-position: center center;
	background-repeat: no-repeat;
	background-size: cover;
	border-radius: 5px;
	display: flex;
	height: 100%;
	justify-content: center;
	position: relative;
	width: 100%;

	&.dragging {
		z-index: 11;
		@include box-shadow(2px, 2px, 10px, rgba(0, 0, 0, 0.1), false);

		&:hover .overlay {
			display: none;
		}
	}

	&:hover {
		.overlay {
			display: flex;
		}
	}

	.overlay {
		align-items: center;
		background-color: rgba(0, 0, 0, 0.7);
		border-radius: 5px;
		bottom: 0;
		color: white;
		display: none;
		justify-content: center;
		left: 0;
		position: absolute;
		right: 0;
		top: 0;

		a {
			cursor: pointer;
		}
	}
}


