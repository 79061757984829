@import '@styles/variables';

.root {
	&.hover :hover {
		background-color: $white-grey;
		cursor: pointer;

		.angle-right {
			color: $body-font-color;
		}
	}

	.angle-right {
		color: $mystery-grey;
	}
}