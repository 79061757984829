.tooltipTriangle {
  content: '';
  position: absolute;
  top: -10px;
  right: 40px;
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 10px solid #373d3f;
}
