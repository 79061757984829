@import '@styles/variables';

.root {
	position: relative;
	display: flex;
	flex-direction: column;
	width: 100%;
	cursor: pointer;
	border: 2px solid white;
	border-bottom: 2px dashed $line-color;

	&:hover {
		border: 2px solid $line-color;

		> .label {
			display: flex;
		}
	}
}

.add-group {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
	padding: 10px;
	color: $line-color;
	font-size: 14px;
	text-decoration: none;
	outline: none;
	cursor: pointer;

	&:hover {
		color: $blue;
	}
}
