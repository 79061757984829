@import '@styles/variables';

.root {
	position: relative;
	display: flex;
	flex-direction: column;
	width: 100%;
	min-height: 100px;
	background-size: cover;
	background-position: center center;
	background-repeat: no-repeat;
	cursor: pointer;
	border: 2px solid white;

	&:hover {
		border-color: $line-color;

		> .label {
			display: flex;
		}
	}

	&.active:after {
		content: '';
		position: absolute;
		z-index: 1;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		background-color: rgba(2, 183, 226, 0.3);	
	}

	.label {
		display: none;
		align-items: center;
		justify-content: center;
		position: absolute;
		top: 0px;
		left: 0px;
		height: 19px;
		padding: 5px 8px;
		font-size: 10px;
		color: white;
		background-color: $line-color;
		pointer-events: none;
	}
}

.content-holder {
	position: relative;
	display: flex;
	flex-direction: column;
	width: calc(100% - 30px);
	height: 100%;
	margin: 0 auto;

	&.row {
		flex-direction: row;
	}

	&.full-width {
		width: 100%;
	}
}

@media screen and (min-width: 769px) {
	.content-holder {
		&.six-fifty {
			width: 650px;
		}
	}
}

@media screen and (min-width: 1025px) {
	.content-holder {
		&.eight-fifty {
		width: 850px;
		}
	}
}

@media screen and (min-width: 1200px) {
	.content-holder {
		&.ten-fifty {
			width: 1050px;
		}
	}
}
