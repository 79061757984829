@import '@styles/mixins';
@import '@styles/variables';
@import '@styles/animations';

.root {
	display: inline-block;
	position: relative;
	cursor: pointer;

	&:hover .hint {
		display: block;
	}

	.hint {
		display: none;
		position: absolute;
		z-index: 10;
		left: -4px;
		top: calc(100% + 5px);
		width: fit-content;
		font-size: 11px;
		background-color: $dark-grey;
		color: white;
		padding: 6px 8px;
		pointer-events: none;
		white-space: pre;
		@include round-corner(5px);
		@include animation('fadeIn 0.7s 1');

		&::before {
			display: block;
			position: absolute;
			top: -5px;
			left: 4px;
			content: '';
			width: 0;
			height: 0;
			border-left: 5px solid transparent;
			border-right: 5px solid transparent;
			border-bottom: 5px solid $dark-grey;
		}

		&.alternate {
			left: calc(-140px + 100%);

			&::before {
				left: auto;
				right: 4px;
			}
		}

		&.centred-arrow::before {
			left: calc(50% - 2.5px);
			right: auto;
		}
	}
}
