@import '@styles/mixins';
@import '@styles/variables';

.root {
	position: relative;
}

.toggle {
	position: absolute;
	display: flex;
	align-items: center;
	justify-content: center;
	z-index: 5;
	font-size: 24px;
	line-height: 1;
	padding: 0 6px;
	cursor: pointer;
	background-color: white;
	border: 1px solid $line-color;
	@include round-corner(5px);

	&:focus {
		outline: none;
	}

	&:hover {
		color: white;
		border-color: $body-font-color;
		background-color: $body-font-color;
	}
}
