@import '@styles/variables';

.root {
	cursor: pointer;
	border: 1px solid $line-color;
	flex-shrink: 0;
	margin: 16px auto;
	position: relative;

	&.active {
		border-color: $blue;
	}
}
