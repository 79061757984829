@import '@styles/variables';
@import '@styles/mixins';

.root {
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	height: 80px;
	background-color: #f8f8f8;
	font-size: 9px;
    letter-spacing: 1.2px;
	color: $body-font-light-color;
	@include box-shadow(0px, 2px, 3px, rgba(0, 0, 0, 0.1), true);

	&.dark {
		background-color: $dark-grey;
		color: rgba(255, 255, 255, 0.5);
		box-shadow: none;
	}
}

.logo {
	margin-top: 5px;
}