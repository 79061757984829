@import '@styles/variables';
@import '@styles/mixins';

.root {
	display: flex;
	align-items: center;
	justify-content: center;
	background-position: center center;
	background-repeat: no-repeat;
	background-size: cover;

	.change-text,
	.change-icon {
		display: none;
	}

	&.editable {
		position: relative;
		border: 1px dashed $line-color;
		cursor: pointer;

		.change-text {
			color: white;
			display: flex;
			align-items: center;
			justify-content: center;
			position: absolute;
			left: 10px;
			bottom: 10px;
			background-color: rgba(0, 0, 0, 0.6);
			font-size: 12px;
			padding: 5px 10px;
			cursor: pointer;
			@include round-corner(5px);
		}

		&:hover {
			border-color: $blue;
			opacity: 0.9;

			.change-text {
				background-color: rgba(2, 183, 226, 0.71);
			}
		}
	}
}
