.root {
	position: relative;

	.drag-handle {
		display: flex;
		align-items: center;
		justify-content: center;
		position: absolute;
		top: 0;
		bottom: 0;
		left: 0;
		width: 16px;
		background-color: #ececec;
		border-top-left-radius: 5px;
		border-bottom-left-radius: 5px;
	}

	&:hover,
	&.dragging {
		border-color: #78C0B7;

		.drag-handle {
			color: white;
			background-color: #78C0B7;
		}
	}
}
