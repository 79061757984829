@import '@styles/mixins';

.root {
	position: relative;

	&.dragging {
		border-color: #78C0B7;
		@include box-shadow(2px, 2px, 10px, rgba(0, 0, 0, 0.1), false);
	}
}
